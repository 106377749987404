<template>
  <div class="home" >
    <VueSlickCarousel class="banners" :autoplay="true" :arrows="false" :dots="true">
      <div>
        <img src="/img/home/test-1.jpg" alt="" srcset="">
      </div>
      <div>
        <img src="/img/home/test-1.jpg" alt="" srcset="">
      </div>
      <div>
        <img src="/img/home/test-1.jpg" alt="" srcset="">
      </div>
    </VueSlickCarousel>
    <div class="icons-box" v-if="user.roles">
        <template v-if="user.roles[0].indexOf('distributor')==-1">
          <div class="in-box" @click="linkShop()"
               v-if="
                user&&

                (
                  user.user.dept.channel=='常规渠道'||
                  user.user.dept.channel=='广阔渠道'||
                  user.user.dept.channel=='医疗创新'||
                  user.user.dept.channel=='常规CSM'||
                  user.user.dept.channel=='广阔CSM'||
                  user.user.dept.channel=='管理员'||
                  user.user.dept.channel=='总经理室'||
                  user.user.dept.channel=='港台销售部门'||
                  user.user.dept.channel=='大中华区'||
                  user.user.dept.channel=='测试渠道'
                )
              ">
            <div class="img-box">
              <img src="/img/home/icon-1.png">
            </div>
            <div class="fonts-box">订购系统</div>
          </div>
          <div class="in-box" @click="linkActivity()" v-if="user.roles&&user.roles[0]!='digital_project_manager'">
            <div class="img-box">
              <img src="/img/home/icon-2.png">
            </div>
            <div class="fonts-box">区域费用申请</div>
          </div>
          <div class="in-box" @click="linkOrder()"
               v-if="
                user.roles&&
                (
                  user.user.dept.channel=='常规渠道'||
                  user.user.dept.channel=='广阔渠道'||
                  user.user.dept.channel=='医疗创新'||
                  user.user.dept.channel=='常规CSM'||
                  user.user.dept.channel=='广阔CSM'||
                  user.user.dept.channel=='管理员'||
                  user.user.dept.channel=='总经理室'||
                  user.user.dept.channel=='港台销售部门'||
                  user.user.dept.channel=='测试渠道'
                )
              ">
            <div class="img-box">
              <img src="/img/home/icon-3.png">
            </div>
            <div class="fonts-box" >我的订单</div>
          </div>
          <div class="in-box" @click="examineShow=true"
              v-if="
                user.roles&&
                (
                  user.roles[0]=='regional_manager'||
                  user.roles[0]=='sales_director'||
                  user.roles[0]=='financial_staff'||
                  user.roles[0]=='product_manager'||
                  user.roles[0]=='medical_regional_assistant'||
                  user.roles[0]=='regional_marketing_manager'||
                  user.roles[0]=='sale_manager'||
                  user.roles[0]=='sales_empowerment_manager' ||
                  user.roles[0]=='channel_manager'
                )
              "
          >
            <div class="img-box">
              <img src="/img/home/icon-9.png">
            </div>
            <div class="fonts-box">我的审批</div>
          </div>
      </template>
      <template v-if="user.user.weeklyRole">
        <div class="in-box" @click="linkWeekly()">
          <div class="img-box">
            <img src="/img/home/icon-8.png">
            <div class="new-weekly-notice"  v-if="user.user.newWeeklyNotice"></div>
          </div>
          <div class="fonts-box" >我的周报</div>
        </div>
      </template>
      <div class="in-box" @click="linkFiles()">
        <div class="img-box">
          <img src="/img/home/icon-5.png">
        </div>
        <div class="fonts-box">下载系统</div>
      </div>


      <template v-if="user.roles&& user.roles[0].indexOf('distributor')==-1 && user.roles[0]!='digital_project_manager' ">
        <div class="in-box" @click="trackShow=true">
          <div class="img-box">
            <img src="/img/home/icon-4.png">
          </div>
          <div class="fonts-box">订单追踪</div>
        </div>
      </template>

      <div class="in-box" @click="linkNews()">
        <div class="img-box">
          <img src="/img/home/icon-6.png">
        </div>
        <div class="fonts-box">信息发布</div>

      </div>


      <template>
        <div class="in-box" v-if="activeOne.questionnaire" @click="linkUrl(activeOne.questionnaire.link)">
          <div class="img-box">
            <img src="/img/home/icon-11.png">
          </div>
          <div class="fonts-box">问卷调查</div>
        </div>
        <div class="in-box" v-if="!activeOne.questionnaire" @click="noQuestionnaire=true">
          <div class="img-box">
            <img src="/img/home/icon-11.png">
          </div>
          <div class="fonts-box">问卷调查</div>
        </div>
        <div class="in-box" @click="linkAirview()">
          <div class="img-box">
            <img src="/img/home/icon-12.png">
          </div>
          <div class="fonts-box">Airview反馈</div>
        </div>
        <div class="in-box" @click="linkUrl('https://www.resmedbelonging.com/vr2022/index.html')">
          <div class="img-box">
            <img src="/img/home/icon-13.png">
          </div>
          <div class="fonts-box">在线体验店</div>
        </div>
      </template>



      <template>
        <div class="in-box" >
          <div class="img-box">
            <img src="/img/home/icon-10.png">
            <div class="is-close">暂未开放</div>
          </div>
          <div class="fonts-box">交流板块</div>
        </div>
      </template>
      <div class="in-box">
        <div class="img-box">
          <img src="/img/home/icon-7.png">
          <div class="is-close">暂未开放</div>
        </div>
        <div class="fonts-box">门头展示</div>
      </div>
        <!-- <div class="in-box" v-if="activeOne.questionnaire" @click="linkUrl(activeOne.questionnaire.link)">
          <div class="img-box">
            <img :src="baseUrl+activeOne.questionnaire.logo" style="max-width:80%">
          </div>
          <div class="fonts-box">{{activeOne.questionnaire.title}}</div>
        </div> -->



        <div class="in-box"></div>
        <div class="in-box"></div>
        <div class="in-box"></div>
  </div>
    <div class="noQuestionnaire" v-if="noQuestionnaire">
      <img src="/img/empty.png" class="empty">
      <div class="title">暂时还没有问卷调查哦</div>
      <div class="btn-back" @click="noQuestionnaire=false">返回</div>
    </div>
    <Examine :user="user" v-if="examineShow"></Examine>
    <Track :user="user" @close="trackShow=false" v-if="trackShow"></Track>

    <Footer v-model="user" :id="1"></Footer>
  </div>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import Footer from '../components/Footer.vue'
import Examine from '../components/Examine.vue'
import Track from '../components/Track.vue'
export default {
  name: 'Home',
  data: function () {
    return {
      examineShow:false,
      trackShow:false,
      user:{},
      activeOne:{},
      baseUrl:process.env.VUE_APP_BASE_URL,
      noQuestionnaire:false,
      weekly:0
    }
  },
  components: { VueSlickCarousel,Footer,Examine,Track},
  methods:{
    linkWeekly(){
      //
      var vm = this
      this.$axios.put('/business/weekly/notice/read', {}).then(function (res) {
        // console.log(res)
        vm.$router.push({path:'/weekly'})
      })

    },
    linkShop:function(){
      this.$router.push({path:'/shop'})
    },
    linkOrder:function(){
      this.$router.push({path:'/order'})
    },
    linkActivity:function(){
      this.$router.push({path:'/activity'})
    },
    linkFiles:function(){
      this.$router.push({path:'/files'})
    },
    linkTrack:function(){
      this.$router.push({path:'/track'})
    },
    linkNews:function(){
      this.$router.push({path:'/news'})
    },
    linkAirview:function(){
      this.$router.push({path:'/airview'})
    },
    linkUrl(url){
      window.open(url)
      // window.location.href=url
    }
  },
  mounted(){
    var vm = this
    this.$axios.get('/business/questionnaire/activeOne', {}).then(function (res) {
      // console.log(res)
      vm.activeOne=res
    })
    if(this.$route.query.type){
      window.location.href='/'+this.$route.query.type+window.location.search
    }
    if(this.$route.query.history){
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', function () {
          history.pushState(null, null, document.URL);
      });
    }
    // this.$axios.get('/business/weekly/list/my', {}).then(function (res) {
    //   // console.log(res)
    //   vm.weekly=res
    // })
    //
  }
}
</script>
